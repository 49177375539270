import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import authentication from "@util/authentication"
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import 'normalize.css'


const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { locale, size: 'medium' });
app.mount('#app');
authentication(router);