export function handleRoute(routeList=[]){
    let temp = {}
    let result = []
    // 获取第一层
    routeList.forEach(item => {
      if (item.pid == "" || item.pid == null || item.pid == "0") {
        temp[item.id] = item
      }
    })
    // 获取子菜单
    routeList.forEach(item => {
      if (item.pid && temp[item.pid]) {
        if (temp[item.pid].children) {
          temp[item.pid].children.push(item)
        } else {
          temp[item.pid].children = [item]
        }
      }
    })



    Object.keys(temp).forEach(key => {
        result.push(temp[key])
    })
    return result;
}

export const menuList = [
  // {
  //   title: '菜单列表',
  //   path: '/menu/list',
  //   component: 'views/menu-manage/MenuList.vue',
  //   parentId: 1,
  //   parentTitle: '菜单管理',
  // },
 
  // {
  //   title: '字典列表',
  //   path: '/dictionary/list',
  //   component: 'views/dictionary/dictionary-list.vue',
  //   parentId: 3,
  //   parentTitle: '字典管理',
  // },
  // {
  //   title: 'SIM卡列表',
  //   path: '/card/list',
  //   component: 'views/card/card-list.vue',
  //   parentId: 20,
  //   parentTitle: 'SIM卡管理',
  // },
  // {
  //   title: '设备列表',
  //   path: '/owned-equipment/list',
  //   component: 'views/owned-equipment/owned-equipment-list.vue',
  //   parentId: 4,
  //   parentTitle: '设备管理',
  // },
  // {
  //   title: '订单列表',
  //   path: '/order/list',
  //   component: 'views/owned-equipment/order-list.vue',
  //   parentId: 4,
  //   parentTitle: '设备管理',
  // },
  // {
  //   title: '出库记录',
  //   path: '/warehouse/list',
  //   component: 'views/warehouse/warehouse-list.vue',
  //   parentId: 5,
  //   parentTitle: '出库管理',
  // },
  // {
  //   title: '新增出库',
  //   path: '/warehouse/add',
  //   component: 'views/warehouse/add-warehouse.vue',
  //   parentId: 5,
  //   parentTitle: '出库管理',
  // },
  // {
  //   title: '退换货记录',
  //   path: '/tuihuan/list',
  //   component: 'views/tuihuan/tuihuan-list.vue',
  //   parentId: 6,
  //   parentTitle: '售后管理',
  // },

  {
    id: 10000,
    title: '设备管理'
  },
  {
    title: '设备列表',
    url: '/owned-equipment/list',
    component: 'views/owned-equipment/owned-equipment-list.vue',
    pid: 10000
  },
  {
    title: '订单列表',
    url: '/order/list',
    component: 'views/owned-equipment/order-list.vue',
    pid: 10000
  },
]