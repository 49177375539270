import httpRequest from "./request";

// 获取验证码
export function digitalCaptcha() {
  return httpRequest({
    url: "/admin/api/captcha/digitalCaptcha",
    method: "get",
    data: {}
  });
}

// 获取用户菜单列表
export function getMenuList() {
  return httpRequest({
    url: "/assets/open/menus/userMenus",
    method: "get",
    data: {}
  });
}
// 修改密码
export function updatePassword(data) {
  return httpRequest({
    url: `/admin/open/customContact/updatePassword?oldPassword=${data.oldPassword}&newPassword=${data.newPassword}`,
    method: "post",
    data:{}
    // data: new URLSearchParams(data)
  });
}

// 登录
export function login(data) {
  return httpRequest({
    // url: "/assets/login",
    url: "/admin/trade/login",
    method: "post",
    data
  });
}
// 退出登录
export function loginout() {
  return httpRequest({
    url: "/logout",
    method: "post",
    data: {}
  });
}
// 查看用户历史订单
export function getOrderList() {
  return httpRequest({
    url: "/getOrderList",
    method: "get",
    data: {}
  });
}