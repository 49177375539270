<template>
  <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="false"
    title="修改密码"
    width="40%"
    destroy-on-close
    @close="close"
  >
    <el-form label-width="0">
      <el-form-item>
        <el-input v-model="userInfo.oldPassword" placeholder="旧密码" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="userInfo.newPassword" type="password" placeholder="新密码"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" style="text-align:right;padding-top:10px">
      <el-button type="primary" @click="commit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { updatePassword } from "@/api/user";
import { ElMessage } from "element-plus";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const router = useRouter();
    const userInfo = reactive({
      oldPassword: "",
      newPassword: ""
    });
    function close() {
      context.emit("update:dialogVisible", false);
    }
    async function commit() {
        const param = {
            oldPassword: userInfo.oldPassword,
            newPassword: userInfo.newPassword
        }
        const res = await updatePassword(param)
        if (res) {
            ElMessage.success('修改成功')
            setTimeout(() => {
                sessionStorage.clear();
                router.push("/login");
            }, 2000)
        } else {

        }
    }

    return {
      close,
      commit,
      userInfo
    };
  }
};
</script>

<style>
</style>