import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/owned-equipment/list',
    children: [
      { 
        path: '/owned-equipment/list',
        name: 'eq-list',
        title: '设备列表',
        component: () => import(/* webpackChunkName: "login" */ '../views/owned-equipment/owned-equipment-list.vue')
      },
      { 
        path: '/order/list',
        name: 'order-list',
        title: '订单列表',
        component: () => import(/* webpackChunkName: "login" */ '../views/owned-equipment/order-list.vue')
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
