<template>
  <el-container style="height: 100%">
    <el-aside
      :width="isCollapse ? 'auto' : '220px'"
      style="background-color: rgb(238, 241, 246)"
      class="diy-menu"
      v-if="showMenu"
    >
      <div class="nav-title-box">
        <svg
          viewBox="64 64 896 896"
          color="rgb(8, 211, 169)"
          focusable="false"
          class="logo"
          data-icon="gitlab"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M913.9 552.2L805 181.4v-.1c-7.6-22.9-25.7-36.5-48.3-36.5-23.4 0-42.5 13.5-49.7 35.2l-71.4 213H388.8l-71.4-213c-7.2-21.7-26.3-35.2-49.7-35.2-23.1 0-42.5 14.8-48.4 36.6L110.5 552.2c-4.4 14.7 1.2 31.4 13.5 40.7l368.5 276.4c2.6 3.6 6.2 6.3 10.4 7.8l8.6 6.4 8.5-6.4c4.9-1.7 9-4.7 11.9-8.9l368.4-275.4c12.4-9.2 18-25.9 13.6-40.6zM751.7 193.4c1-1.8 2.9-1.9 3.5-1.9 1.1 0 2.5.3 3.4 3L818 394.3H684.5l67.2-200.9zm-487.4 1c.9-2.6 2.3-2.9 3.4-2.9 2.7 0 2.9.1 3.4 1.7l67.3 201.2H206.5l57.8-200zM158.8 558.7l28.2-97.3 202.4 270.2-230.6-172.9zm73.9-116.4h122.1l90.8 284.3-212.9-284.3zM512.9 776L405.7 442.3H620L512.9 776zm157.9-333.7h119.5L580 723.1l90.8-280.8zm-40.7 293.9l207.3-276.7 29.5 99.2-236.8 177.5z"
          />
        </svg>
        能加充值系统
      </div>
      <el-menu
        class="el-menu-vertical-demo"
        @select="menuSelect"
        :isCollapse="isCollapse"
        :uniqueOpened="true"
      >
        <el-menu-item index="/owned-equipment/list">
          <el-icon>
            <document />
          </el-icon>
          <span>设备列表</span>
        </el-menu-item>
        <el-menu-item index="/order/list">
          <el-icon>
            <setting />
          </el-icon>
          <span>订单列表</span>
        </el-menu-item>

        <!-- <component
          v-for="item in navList"
          :key="item.title"
          :is="
            item.children && item.children.length > 0
              ? 'el-sub-menu'
              : 'el-menu-item'
          "
          :index="item.title + item.path"
        >
          <template #title>
            <div>
              <i v-if="item.icon" :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </div>
          </template>
          <template v-if="item.children && item.children.length > 0">
            <el-menu-item
              v-for="child in item.children"
              :key="child.url"
              :index="child.url"
            >
              <i v-if="child.icon" :class="child.icon"></i>
              <span>{{ child.title }}</span>
            </el-menu-item>
          </template>
        </component>-->
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <el-dropdown v-if="!showMenu" @command="handleCommand">
          <span class="el-dropdown-link">
            <img class="menu-icon" src="@/assets/menu.png" />菜单
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="/owned-equipment/list">设备列表</el-dropdown-item>
              <el-dropdown-item command="/order/list">订单列表</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <!-- <span class="out" @click="loginout">退出</span> -->
        <el-dropdown class="out" @command="handleCommand2">
          <span class="el-dropdown-link">
            {{ username }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="handlePwd">修改密码</el-dropdown-item>
              <el-dropdown-item command="loginout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <ChangePassword v-model:dialogVisible="dialogVisible"></ChangePassword>
      </el-header>
      <el-main style="background-color: #f8f8f9">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
<script setup>
import { ref, toRaw, onMounted } from "vue";
import ChangePassword from "@/components/change-password.vue"
import { ArrowRightBold } from "@element-plus/icons";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { handleRoute } from "@/util/index";

const router = useRouter();
const store = useStore();
const isCollapse = ref(false);
const showMenu = ref(true);
const navList = handleRoute(toRaw(store.state.menuList));
const dialogVisible = ref(false)
const username = ref(sessionStorage.getItem('username'))

onMounted(() => {
  showMenu.value = document.body.clientWidth > 500;
});

function menuSelect(key, keyPath) {
  router.push(key);
}
function loginout() {
  sessionStorage.clear();
  router.push("/login");
}
function handleCommand(url) {
  router.push(url);
}
function handleCommand2(cmd) {
  if (cmd == 'loginout') {
    loginout()
  } else if (cmd == 'handlePwd') {
    dialogVisible.value = true
  }
}
</script>
<style lang="less">
@mainBg: #040d67 !important;
@itemHover: rgba(0, 0, 0, 0.6);
.el-main {
  background-color: #f8f8f9;
}
.el-aside {
  background-color: @mainBg;
}
.el-menu {
  background-color: @mainBg;
  .el-menu-item,
  .el-sub-menu__title span {
    color: #fff;
  }
  .el-menu-item,
  .el-sub-menu__title {
    &:hover {
      background-color: @itemHover;
    }
  }
}
.el-aside .el-sub-menu .el-menu {
  background-color: #010230 !important;
}
.el-sub-menu__title:hover {
  background-color: @itemHover;
}
.nav-title-box {
  padding: 20px 0;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  .el-icon-sunny {
    font-size: 32px;
    color: yellow;
  }
  .logo {
    font-size: 30px;
    position: relative;
    top: 8px;
    left: -5px;
  }
}
.el-sub-menu__title {
  display: flex;
  justify-content: space-between;
  .el-icon {
    color: #fff;
  }
}

// @media screen and (max-width: 900px) {
//   .diy-menu {
//     display: none;
//   }
// }
.menu-icon {
  position: relative;
  top: 12px;
  cursor: pointer;
}
.out {
  float: right;
  margin-right: 10px;
  position: relative;
  top: 16px;
  font-size: 14px;
  cursor: pointer;
  color: blue;
}
</style>