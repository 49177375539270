import { createStore } from 'vuex'

function getMenuList () {
  let list = []
  try{
    list = JSON.parse(sessionStorage.getItem("menList")) || [];
  }catch(err){
    list = []
  }
  return list;
}

export default createStore({
  state: {
    menuList: getMenuList(),
    paymentList: []
  },
  mutations: {
    SET_MENTLIST(state, val) {
      state.menuList = val;
    },
    SET_PAYMENTLIST(state, val) {
      state.paymentList = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
