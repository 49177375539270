import { menuList } from "./index";
import store from "@/store/index";
import { getMenuList } from "@/api/user";

async function getMenuListHandel() {
  // let res = await getMenuList();
  let menuListResult = menuList
  store.commit("SET_MENTLIST", menuListResult);
  sessionStorage.setItem("menuList", JSON.stringify(menuListResult));
  return menuListResult.reduce((prev, next) => {
    if (next.pid && next.pid != "") {
      prev.push(next);
    }
    return prev;
  }, []);
}


let isShow = false;
export default function authentication(router) {
  router.beforeEach(async (to, from, next)=>{
    let token = sessionStorage.getItem("token");
    if (token && token != undefined) {
      if (!isShow) {
        // console.log('add')
        // let list = await getMenuListHandel();
        // console.log(list);
        // renderRoute(router, list);
        isShow = true
        next(to.fullPath)
      } else {
        next();
      }
    } else {
      if (to.path == "/login") {
        next();
        return;
      }
      next("/login");
    }
  })
}

function renderRoute(router, routerList){
  routerList.forEach(item => {
    if (item.component && item.url) {
      router.addRoute('home', {
        title: item.title,
        path: item.url,
        component: ()=> import(`../${item.component}`)
      })
    }
  })
}
