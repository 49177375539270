import axios from "axios";
import { ElMessage } from "element-plus";

// const GlobalUrl = "http://120.79.180.88:8888/";

const GlobalUrl = "http://47.112.108.106:10001";
// const GlobalUrl = "http://192.168.1.124:8000";
const config = {
  baseURL: GlobalUrl
  // withCredentials: true
};
const Axios = axios.create(config);
// 请求前拦截
Axios.interceptors.request.use(resconfig => {
  if (!resconfig.url?.includes("digitalCaptcha") && !resconfig.url?.includes("login")) {
    const token = sessionStorage.getItem("token");
    resconfig.headers["Authorization"] = 'Bearer ' + token;
  }
  if (resconfig.data.contentType == "form") {
    resconfig.data = new URLSearchParams(resconfig.data);
  }

  return resconfig;
});
// 响应拦截
Axios.interceptors.response.use(
  res => {
    if (typeof res.data == "string") { // 下载文件相关接口
      return res;
    }
    if (res.data.code === 10000) {
      return res;
    } else if (res.data.code === 401) {
      ElMessage.error(res.data.msg || "数据返回异常");
      sessionStorage.clear();
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } else {
      ElMessage.error(res.data.msg || "数据返回异常");
      return null;
    }
  },
  err => {
    ElMessage.error(err.response.data.msg);
    return null;
  }
);
export default function httpRequest(options) {
  return Axios(options);
}